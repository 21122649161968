:root {
  --input-color: #99A3BA;
  --input-color-green: #00ab569d;
  --input-color-green-dark: #00ab56;
  --input-color-disabled: #00000094;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;
  --input-border-focus: #00AB55;
  --input-disabled-background: #d3d3d396;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #094d2b;
  --background-light-green: rgb(0 171 86 / 13%);
  --background-dark-green: rgb(6 72 39 / 84%);
}

.rightTxtAlign {
  text-align: right;
}

.leftTxtAlign {
  text-align: left;
}

.boxContainer {
  display: flex;
  flex: 1;
  padding: 0 10px;
  justify-content: space-between
}

.splitSpace {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: center;
  line-height: 1.5rem;
}

.formField {
  display: block;
  width: 80px;
  padding: 8px 4px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}

.disabledInput {
  background: var(--input-disabled-background) !important;
  color: var(--input-color-disabled) !important;
  text-align: center !important;
}

.approvedInput {
  background: #00ab56c7 !important;
  color: white !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.notincludedInput {
  cursor: no-drop;
  background: lightslategray;

}

.savedInput {
  background: var(--background-light-green) !important;
  color: var(--input-color-green-dark) !important;
  font-weight: 600 !important;
}

.buttonIn {
  position: relative;
}

input {
  margin: 0px;
  padding: 8px 4px;
  width: 80%;
  outline: none;
  height: 30px;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}

.addTime {
  position: absolute;
  top: 3px;
  right: 2px;
  z-index: 2;
  border: none;
  color: var(--input-color-green);
  transform: translateX(2px);
}

.noteTxt {
  color: #ff48429e;
  font-size: 13px;
  font-style: italic;
  padding-top: .5rem;
}

/* ---------------------------------------------------------------------------------------------------------------------- */
/* Timesheet Approve Form css */

button.clearBtn:hover {
  text-decoration: none;
  background-color: rgb(171 0 0 / 8%);
}

.cardBackground {
  display: flex;
  /* min-height: 200px; */
  background: url(https://img.freepik.com/free-vector/businessman-planning-events-deadlines-agenda_74855-6274.jpg?w=1380&t=st=1679750770~exp=1679751370~hmac=cdf8e13…);
  background-size: cover;
  background-size: auto 500px;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 0;
}

.cardBox {
  display: flex;
  width: 100%;
  /* height: 100%; */
  padding: 20px;
  background: var(--background-dark-green);
  background-image: cover;

}


.approveTimesheetEditableCell>div>div>input {
  padding: 5px 10px !important;
}